import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/54654bf8/website/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Playground } from 'docz';
import { GetFloatLen, SetFloatLen, ToFixed, ToggleBasicFloatLen } from '@mini-code/base-func/number';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "函数调用"
    }}>{`函数调用`}</h1>
    <p>{`basic-helper 提供统一的函数调用过滤器`}</p>
    <h2 {...{
      "id": "遇到的问题"
    }}>{`遇到的问题`}</h2>
    <p>{`在程序运行的过程中，调用不确定的函数时，由于异步机制，遇到该函数尚未定义，则会导致调用错误，所以 basic-helper 提供了函数调用方式`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`if(typeof func == 'function') func();
`}</code></pre>
    <h2 {...{
      "id": "call"
    }}>{`Call`}</h2>
    <p>{`Call 的第一个参数为目标函数，如果第一个参数为函数，则后面的参数将会传入第一个参数的回调`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Call } from 'basic-helper/call'

function func(arg1, arg2) {
  // ...
}

Call(func, arg1, arg2);
`}</code></pre>
    <h2 {...{
      "id": "callfunc"
    }}>{`CallFunc`}</h2>
    <p>{`CallFunc 只接受一个参数，如果该参数为函数，则直接返回该函数，否则返回一个空函数`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { CallFunc } from 'basic-helper/call'

function func(arg1, arg2) {
  // ...
}

CallFunc(func)(arg1, arg2);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      